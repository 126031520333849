import React from 'react';
import { InteliigemsUpgradePage } from '@/features/new-landing-page/upgrade/inteliigems';

export default function IgUpgrage() {
  return <InteliigemsUpgradePage />;
}

export const Head = () => (
  <>
    <title>Intelligent Testing Starts Here - Visually.io</title>
    <script
      charSet="utf-8"
      type="text/javascript"
      src="//js-eu1.hsforms.net/forms/embed/v2.js"
    />
  </>
);
