import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { HubspotForm } from '@/features/new-landing-page/components/hubspot-form';
import { GlobalStyles } from '@/styles/globalStyles';
import { Header } from '@/features/new-landing-page/components/header';
import { Footer } from '@/features/new-landing-page/components/footer';
import { PageWrapper } from '@/features/new-landing-page/pages/homepage';
import { VisuallyLogo } from '@/features/strapi/components/logo';
import { breakpoints } from '@/components/responsive';

export function InteliigemsUpgradePage() {
  return (
    <PageWrapper>
      <GlobalStyles />
      <Header />
      <Wrapper>
        <MainBanner>
          <Padding>
            <StaticImage
              className="only-desktop"
              width={3000}
              quality={100}
              placeholder="none"
              loading="eager"
              src="./1_desk.png"
              alt="Main Banner"
            />
            <StaticImage
              className="only-mobile"
              quality={100}
              placeholder="none"
              loading="eager"
              src="./1_mobile.png"
              alt="Main Banner"
            />
          </Padding>
          <FormWrapper>
            <VisuallyLogo wide />
            <h1>Intelligent Testing Starts Here</h1>
            <HubspotForm
              formId="28718c82-b439-4651-b100-1e629c365d9e"
              portalId="143410811"
              submitText="Schedule a call"
              additionalCss={FORM_CSS}
            />
          </FormWrapper>
        </MainBanner>
        <Padding>
          <StaticImage
            className="only-desktop"
            width={3000}
            quality={100}
            placeholder="none"
            loading="eager"
            src="./5_desk.png"
            alt="Customers"
          />
          <StaticImage
            className="only-mobile"
            quality={100}
            placeholder="none"
            loading="eager"
            src="./5_mobile.png"
            alt="Customers"
          />
        </Padding>
        <Padding>
          <StaticImage
            className="only-desktop"
            width={3000}
            quality={100}
            placeholder="none"
            loading="eager"
            src="./3_desk.png"
            alt="Why Visually?"
          />
          <StaticImage
            className="only-mobile"
            quality={100}
            placeholder="none"
            loading="eager"
            src="./3_mobile.png"
            alt="Why Visually?"
          />
        </Padding>
        <a
          href="https://meetings-eu1.hubspot.com/anya-geimanson/goodbye-intelligems?uuid=d5213329-cffa-49b0-99f1-5c5c9aeb9865"
          title="Book a demo"
        >
          <StaticImage
            className="only-desktop"
            width={3000}
            quality={100}
            placeholder="none"
            loading="eager"
            src="./4_desk.png"
            alt="Ready to get started?"
          />
          <StaticImage
            className="only-mobile"
            quality={100}
            placeholder="none"
            loading="eager"
            src="./4_mobile.png"
            alt="Ready to get started?"
          />
        </a>
        <Padding>
          <BorderRadius>
            <StaticImage
              className="only-desktop"
              width={3000}
              quality={100}
              placeholder="none"
              loading="eager"
              src="./6_desk.png"
              alt="Comparison"
            />
            <StaticImage
              className="only-mobile"
              quality={100}
              placeholder="none"
              loading="eager"
              src="./6_mobile.png"
              alt="Comparison"
            />
          </BorderRadius>
        </Padding>
        <a
          href="https://meetings-eu1.hubspot.com/anya-geimanson/goodbye-intelligems?uuid=d5213329-cffa-49b0-99f1-5c5c9aeb9865"
          title="Book a demo"
        >
          <StaticImage
            className="only-desktop"
            width={3000}
            quality={100}
            placeholder="none"
            loading="eager"
            src="./7_desk.png"
            alt="Join Visually"
          />
          <StaticImage
            className="only-mobile"
            quality={100}
            placeholder="none"
            loading="eager"
            src="./7_mobile.png"
            alt="Join Visually"
          />
        </a>
      </Wrapper>
      <Footer />
    </PageWrapper>
  );
}

const Wrapper = styled.div`
  background: white !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;

  overflow-y: scroll;

  ${breakpoints.down(`md`)} {
    gap: 4rem;
  }
`;

const Padding = styled.div`
  padding: 0 5vw;
`;

const BorderRadius = styled.div`
  border-radius: 2rem;
  overflow: hidden;

  ${breakpoints.down(`md`)} {
    border-radius: 1rem;
  }
`;

const MainBanner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  justify-items: center;
  align-items: center;
  background: rgb(246, 246, 246);

  padding: 4rem 0 10rem 0;

  ${breakpoints.down(`md`)} {
    grid-template-columns: 1fr;
    padding: 0rem 0 5rem 0;
  }
`;

const FormWrapper = styled.div`
  background: white;
  border-radius: 2rem;
  border: 2px solid black;
  padding: 4rem;

  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 2rem;
  width: 70%;

  ${breakpoints.down(`md`)} {
    width: 85vw;

    h1 {
      font-size: 1.6rem;
    }
  }

  .hubspot-form {
    width: 100%;
  }
`;

const FORM_CSS = `
  input[type="text"], input[type="email"], textarea {
    border: 1px solid rgb(193,193,204)!important;
    border-radius: 100px!important;
    background: white!important;
    padding: 22px 20px!important;
    font-size: 16px!important;
    color: black;
  }

  input[type="text"]::placeholder, input[type="email"]::placeholder, textarea::placeholder, select::placeholder {
    color: rgb(49,53,57)!important;
  }

  select {
    background: white!important;
    border-radius: 100px!important;
    width: 100%!important;
    height: 46px!important;
    border: 1px solid rgb(193,193,204)!important;
    color: black!important;
    appearance: none!important;
    font-size: 16px!important;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="rgb(49,53,57)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>') no-repeat right 18px center!important;
    background-size: 20px!important;
    padding-left: 20px!important;
  }

  select.invalid {
    color: rgb(49,53,57)!important;
  }

  input.hs-button[type="submit"] {
    background: black!important;
    border-radius: 100px !important;
    color: white !important;
    border-color: black!important;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  }

  .submitted-message {
    color: black!important;
    text-align: center!important;
  }
`;
